import React, { useEffect, useState } from "react";
import { db } from "../../App";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { formatBase64Image } from "../Functions";
import { MdAccountCircle } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { Provider } from "../store/actions/types";

interface ProviderData {
  UID: string;
  emailAddress: string;
  rate: number;
  priority: number;
  profileImage?: string;
}

interface ManageProvidersModalProps {
  isOpen: boolean;
  onClose: () => void;
  areaDocId: string;
}

const ManageProvidersModal: React.FC<ManageProvidersModalProps> = ({
  isOpen,
  onClose,
  areaDocId,
}) => {
   const providers = useSelector(
     (state: RootState) => state.providerProfileAccounts.accounts
   );
   console.log(providers)

  const [assignedProviders, setAssignedProviders] = useState<any[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [modalType, setModalType] = useState<"manage" | "assign">("manage");
  const [isAssignModalOpen, setAssignModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<any | null>(
    null
  );
  const [priority, setPriority] = useState(1); // Default priority

  useEffect(() => {
    const fetchProviders = async () => {
      if (isOpen && areaDocId) {
        const areaDoc = await getDoc(doc(db, "Zones", areaDocId));
        if (areaDoc.exists()) {
          const areaData = areaDoc.data();
          const assignedProvidersData = areaData.AssignedProviders || [];
          // Merge details and compute priorities
          const mergedProviders = assignedProvidersData.map(
            (ap: any, index: any) => {
              const fullDetails = providers.find((p: Provider) => p.uid === ap.UID);
              return {
                ...fullDetails, // Spread full details to get all provider data
                rate: ap.rate, // Take rate from the assigned data
                priority: index + 1, // Compute priority based on the array position
              };
            }
          );
          setAssignedProviders(mergedProviders);
        } else {
          setAssignedProviders([]);
        }
        setLoading(false);
      }
    };

    fetchProviders();
  }, [isOpen, areaDocId, providers]);

  const mergeWorkingHours = (currentHours:any, newHours:any) => {
    const mergedHours = { ...currentHours };
    for (const day in newHours) {
      if (newHours.hasOwnProperty(day)) {
        const currentDayHours = currentHours[day] || { startTime: "00:00", endTime: "00:00" };
        const newDayHours = newHours[day];
  
        mergedHours[day] = {
          startTime: currentDayHours.startTime < newDayHours.startTime ? currentDayHours.startTime : newDayHours.startTime,
          endTime: currentDayHours.endTime > newDayHours.endTime ? currentDayHours.endTime : newDayHours.endTime,
        };
      }
    }
    return mergedHours;
  };
  
  const mergeSelectedDays = (currentDays:any, newDays:any) => {
    const mergedDays = { ...currentDays };
    for (const day in newDays) {
      if (newDays.hasOwnProperty(day)) {
        mergedDays[day] = mergedDays[day] || newDays[day];
      }
    }
    return mergedDays;
  };

  const handlePrioritySubmit = async () => {
    if (!selectedProvider || priority < 1 || priority > 100) {
      alert("Please enter a valid priority rate between 1 and 100.");
      return;
    }
    //console.log(selectedProvider.uid)

    try {
      const zoneRef = doc(db, "Zones", areaDocId);
      const zoneDoc = await getDoc(zoneRef);
  
      if (zoneDoc.exists()) {
        const zoneData = zoneDoc.data();
        let newAssignedProviders = Array.isArray(zoneData.AssignedProviders)
          ? zoneData.AssignedProviders
          : [];
  
          
        if (!newAssignedProviders.some((ap) => ap.UID === selectedProvider.uid)) {
          newAssignedProviders.push({
            UID: selectedProvider.uid,
            rate: priority,
          });
        }
  
        await updateDoc(zoneRef, {
          AssignedProviders: newAssignedProviders,
        });
  
        setAssignedProviders(
          newAssignedProviders.map((ap, idx) => ({
            ...ap,
            priority: idx + 1,
          }))
        );
  
        // Get the region ID from the zone data
        const regionId = zoneData.regionId;
        const regionRef = doc(db, "Regions", regionId);
        const regionDoc = await getDoc(regionRef);
  
        if (regionDoc.exists()) {
          const regionData = regionDoc.data();
          let updatedWorkingHours = regionData.workingHours || {};
          let updatedSelectedDays = regionData.selectedDays || {};
  
          // Recalculate the working hours and selected days based on all providers in the region
          for (const assignedProvider of newAssignedProviders) {
            const providerDoc = await getDoc(doc(db, "Providers", assignedProvider.UID));
            if (providerDoc.exists()) {
              const providerData = providerDoc.data();
              updatedWorkingHours = mergeWorkingHours(updatedWorkingHours, providerData.workingHours);
              updatedSelectedDays = mergeSelectedDays(updatedSelectedDays, providerData.selectedDays);
            }
          }
  
          await updateDoc(regionRef, {
            workingHours: updatedWorkingHours,
            selectedDays: updatedSelectedDays,
          });
        } else {
          console.error("No such region document!");
          alert("Failed to find the region document. Please check the region ID.");
        }
  
        setAssignModalOpen(false);
      } else {
        console.error("No such document!");
        alert("Failed to find the zone document. Please check the zone ID.");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Failed to assign provider. Please try again.");
    }
  };
  

  const handleRemoveProvider = async (providerIndex: number) => {
    try {
      const zoneRef = doc(db, "Zones", areaDocId);
      const zoneDoc = await getDoc(zoneRef);
  
      if (zoneDoc.exists()) {
        const zoneData = zoneDoc.data();
        const newAssignedProviders = Array.isArray(zoneData.AssignedProviders)
          ? zoneData.AssignedProviders
          : [];
  
        // Remove the provider from the array
        newAssignedProviders.splice(providerIndex, 1);
  
        // Update the priorities of the remaining providers
        newAssignedProviders.forEach((provider, index) => {
          provider.priority = index + 1;
        });
  
        // Update the Firestore document
        await updateDoc(zoneRef, {
          AssignedProviders: newAssignedProviders,
        });
  
        // Update local state
        setAssignedProviders(newAssignedProviders);
  
        // Get the region ID from the zone data
        const regionId = zoneData.regionId;
        const regionRef = doc(db, "Regions", regionId);
        const regionDoc = await getDoc(regionRef);
  
        if (regionDoc.exists()) {
          const regionData = regionDoc.data();
          let updatedWorkingHours = {};
          let updatedSelectedDays = {};
  
          // Recalculate the working hours and selected days based on remaining providers in the region
          for (const assignedProvider of newAssignedProviders) {
            const providerDoc = await getDoc(doc(db, "Providers", assignedProvider.UID));
            if (providerDoc.exists()) {
              const providerData = providerDoc.data();
              updatedWorkingHours = mergeWorkingHours(updatedWorkingHours, providerData.workingHours);
              updatedSelectedDays = mergeSelectedDays(updatedSelectedDays, providerData.selectedDays);
            }
          }
  
          await updateDoc(regionRef, {
            workingHours: updatedWorkingHours,
            selectedDays: updatedSelectedDays,
          });
        } else {
          console.error("No such region document!");
          alert("Failed to find the region document. Please check the region ID.");
        }
      } else {
        console.error("No such document!");
        alert("Failed to find the zone document. Please check the zone ID.");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Failed to remove provider. Please try again.");
    }
  };
  

  const renderAssignModal = () => {
    //console.log("Rendering assign modal:", isAssignModalOpen, selectedProvider);
    return isAssignModalOpen && selectedProvider ? (
      <div style={overlayStyle}>
        <div style={styles.modalSmallStyle}>
          <button
            style={closeButtonStyle}
            onClick={() => setAssignModalOpen(false)}
          >
            &times;
          </button>
          {selectedProvider.profileImage ? (
            <img
              src={formatBase64Image(selectedProvider.profileImage)}
              alt="Provider Profile Icon"
              style={{
                width: "75px",
                height: "75px",
                borderRadius: "50%",
                border: "3px solid #007ae4",
                marginRight: "10px", // Add spacing between image and text
                objectFit: "cover",
              }}
            />
          ) : (
            <MdAccountCircle
              size="65"
              style={{
                borderRadius: "50%",
                border: "3px solid #007ae4",
                marginRight: "10px", // Add spacing between icon and text
                objectFit: "cover",
              }}
            />
          )}
          <p>{selectedProvider.emailAddress}</p>
          <p>Set a Rate for this Provider</p>

          <input
            type="number"
            style={styles.input}
            value={priority}
            min="1"
            max="100"
            onChange={(e) => setPriority(parseInt(e.target.value, 10))}
          />
          <button style={styles.buttonSmall} onClick={handlePrioritySubmit}>
            Submit
          </button>
        </div>
      </div>
    ) : null;
  };

  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const contentStyle: React.CSSProperties = {
    fontFamily: "PoppinsMedium",
    backgroundColor: "white",
    borderRadius: "20px",
    padding: "20px",
    maxWidth: "800px",
    minHeight: "400px",
    width: "100%",
    position: "relative",
    transform: isOpen ? "translateY(0)" : "translateY(100vh)",
    transition: "transform 0.3s ease-in-out",
  };

  const closeButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
    fontFamily: "PoppinsLight",
  };

  const assignButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: "40px",
    right: "40px",
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "white",
    fontFamily: "PoppinsLight",

    padding: "10px 16px", // Increases padding to make the button larger
    fontSize: "16px", // Increases the font size
    //marginTop:10,
    //marginLeft:10,
    //width:100,
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: "black",
  };

  const handleModalAssignClick = () => {
    setModalType("assign");
  };

  const handleAssignProviderClick = (provider: any) => {
    console.log("Assigning provider:", provider.email);
    setSelectedProvider(provider);
    setAssignModalOpen(true);
  };

  const handleBackClick = () => {
    setModalType("manage");
  };

  const renderAvailableProviders = () => {
    const availableProviders = providers.filter(
      (provider: any) =>
        provider.verified &&
        !assignedProviders.some(
          (assignedProvider) => assignedProvider.uid === provider.uid
        )
    );

    return (
      <>
        <h2>Assign Provider</h2>
        {availableProviders.length > 0 ? (
          <ul style={{ margin: 0, padding: 0 }}>
            {availableProviders.map((provider: any) => (
              <div
                key={provider.UID}
                style={{
                  border: "3px solid #007ae4",
                  borderRadius: 20,
                  marginBottom: 20,
                  padding: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Align items evenly along the main axis
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {provider.profileImage ? (
                    <img
                      src={formatBase64Image(provider.profileImage)}
                      alt="Provider Profile Icon"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        border: "3px solid #007ae4",
                        marginRight: "10px", // Add spacing between image and text
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <MdAccountCircle
                      size="35"
                      style={{
                        borderRadius: "50%",
                        border: "3px solid #007ae4",
                        marginRight: "10px", // Add spacing between icon and text
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <p>Email: {provider.emailAddress}</p>
                </div>
                <button
                  style={styles.buttonSmall}
                  onClick={() => handleAssignProviderClick(provider)}
                >
                  Assign
                </button>
              </div>
            ))}
          </ul>
        ) : (
          <p style={styles.centerText}> No available providers to assign.</p>
        )}
        <button style={styles.buttonSmall} onClick={handleBackClick}>
          Back
        </button>
      </>
    );
  };

  return (
    <>
      {isOpen && (
        <div style={overlayStyle} onClick={onClose}>
          <div style={contentStyle} onClick={(e) => e.stopPropagation()}>
            <button style={closeButtonStyle} onClick={onClose}>
              &times;
            </button>
            {modalType === "manage" ? (
              <>
                <button
                  style={assignButtonStyle}
                  onClick={handleModalAssignClick}
                >
                  Assign More Providers
                </button>
                <h2 style={{ fontFamily: "PoppinsSemi" }}>Manage Providers</h2>
                {loading ? (
                  <p>Loading...</p>
                ) : assignedProviders && assignedProviders.length > 0 ? (
                  <ul style={{ margin: 0, padding: 0 }}>
                    {assignedProviders.map((provider, index) => (
                      <div
                        key={provider.UID}
                        style={{
                          border: "3px solid #007ae4",
                          borderRadius: 20,
                          marginBottom: 20,
                          padding: 25,
                          //backgroundColor: "pink",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          position: "relative", // Add this line to enable positioning
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {provider.profileImage ? (
                            <img
                              src={formatBase64Image(provider.profileImage)}
                              alt="Provider Profile Icon"
                              style={{
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                                border: "3px solid #007ae4",
                                marginRight: "10px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <MdAccountCircle
                              size="35"
                              style={{
                                borderRadius: "50%",
                                border: "3px solid #007ae4",
                                marginRight: "10px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                          <p style={{ margin: 0, marginRight: "20px" }}>
                            Email: {provider.emailAddress}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginRight: "20px",
                            }}
                          >
                            <p style={{ margin: 0 }}>Rate: {provider.rate}%</p>
                            <p style={{ margin: 0 }}>
                              Priority: {provider.priority}
                            </p>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <button
                            style={{ ...styles.buttonSmall, fontSize: 13 }}
                            onClick={() => handleAssignProviderClick(provider)}
                          >
                            Edit Assignment
                          </button>
                          <button
                            style={{
                              borderWidth: 0,
                              borderRadius: 100,
                              color: "white",
                              padding: "5px", // Increase vertical padding
                              fontSize: 13,
                              backgroundColor: "red",
                              position: "absolute", // Position the button absolutely
                              top: 2, // Adjust the top and right values as needed
                              right: 5,
                              display: "flex", // Use flexbox
                              alignItems: "center", // Center vertically
                              justifyContent: "center", // Center horizontally
                            }}
                            onClick={() => handleRemoveProvider(index)}
                          >
                            <IoMdClose />
                          </button>
                        </div>
                      </div>
                    ))}
                  </ul>
                ) : (
                  <p style={styles.centerText}>No providers assigned.</p>
                )}
              </>
            ) : (
              renderAvailableProviders()
            )}
            {renderAssignModal()}
          </div>
        </div>
      )}
    </>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}
const styles: Styles = {
  buttonSmall: {
    padding: "10px 16px", // Increases padding to make the button larger
    fontSize: "16px", // Increases the font size
    cursor: "pointer", // Changes the cursor on hover
    //marginTop:10,
    //marginLeft:10,
    color: "white",
    //width:100,
    borderWidth: 0,
    fontFamily: "PoppinsLight",

    borderRadius: 25,
    backgroundColor: "black",
  },
  centerText: {
    padding: "10px 16px",
    fontSize: "20px",
    cursor: "pointer",
    fontFamily: "Poppins",
    color: "black",
    display: "flex", // Use flexbox
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    minHeight: "400px", // Ensure the container has enough height for vertical centering
  },

  input: {
    marginBottom: "25px",
    width: "30%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    fontSize: "16px",
  },

  modalSmallStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1050, // Higher than the overlay of the underlying modal
    width: "300px", // Fixed width for the small modal
    backgroundColor: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ManageProvidersModal;
