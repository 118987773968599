// src/store/actions/dispatchActions.ts
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { db } from '../../../App';
import { getDoc, doc } from 'firebase/firestore';
import { RootState } from '../reducers';
import { fetchCustomers } from './customerActions';
import { fetchProviderDetails } from './providerActions'; // Updated thunk supports both arrays and single IDs
import { SET_DISPATCH_HISTORY, SET_DISPATCH_LOADING, SET_DISPATCH_ERROR } from './types';
import { fetchProcessHistory } from './processHistoryActions';
import { SET_LOADING_PROVIDER_PROFILE_ACCOUNTS } from '../actions/types';

export const fetchDispatchData = (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    // Set dispatch loading and provider accounts loading to true immediately
    dispatch({ type: SET_DISPATCH_LOADING, payload: true });
    dispatch({ type: SET_LOADING_PROVIDER_PROFILE_ACCOUNTS, payload: true });

    try {
      // Step 1: Fetch the process history (reuse your existing action)
      await dispatch(fetchProcessHistory());

      // After process history is loaded, extract it from state
      const { history } = getState().processHistory; // assuming processHistory reducer holds history

      // Step 2: Extract unique IDs from history
      const orderIds = new Set<string>();
      const customerIds = new Set<string>();
      const providerIds = new Set<string>();
      history.forEach((entry: any) => {
        if (entry.orderId) orderIds.add(entry.orderId);
        if (entry.customerUid) customerIds.add(entry.customerUid);
        if (entry.providerUid) providerIds.add(entry.providerUid);
      });

      // Step 3: Re-use your fetchCustomers action
      if (customerIds.size > 0) {
        await dispatch(fetchCustomers(Array.from(customerIds)));
      }

      // Step 4: Fetch orders by IDs.
      const ordersPromises = Array.from(orderIds).map(async (orderId) => {
        const orderDoc = await getDoc(doc(db, 'Orders', orderId));
        return orderDoc.exists() ? { id: orderDoc.id, ...orderDoc.data() } : null;
      });
      const orders = (await Promise.all(ordersPromises)).filter(o => o !== null);
      // Dispatch an action to store these orders.
      dispatch({ type: 'ADD_ORDERS', payload: orders });

      // Step 5: For providers, fetch them all at once.
      if (providerIds.size > 0) {
        await dispatch(fetchProviderDetails(Array.from(providerIds)));
      }

    } catch (error) {
      console.error('Error in fetchDispatchData:', error);
      dispatch({ type: SET_DISPATCH_ERROR, payload: error });
    } finally {
      dispatch({ type: SET_DISPATCH_LOADING, payload: false });
    }
  };
