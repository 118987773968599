import React, { useEffect, useState } from "react";
import Sidebar from "./SideBar";
import Analytics from "./Analytics";
import Dashboard from "./Dashboard";
import Bookings from "./Bookings";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { fetchProviderDetails } from "../store/actions/providerActions";
import { RootState } from "../store/reducers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import EmployeeList from "./EmployeeList";
import Invoices from "./Invoices";
import Clients from "./Client";
import Requests from "./Requests";
import { ToastContainer, toast } from "react-toastify";
import Profile from "./Profile";
import { fetchZoneData } from "../store/actions/zonesActions";
import { fetchOrdersProvider } from "../store/actions/orderActions";
import RateAgreement from "./RateAgreement";
import VehicleFleet from "./VehicleFleet";

const ProvIndex = () => {
  const [selectedTab, setSelectedTab] = useState("Dashboard"); // Default selected tab
  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch();
  const [providerId, setProviderId] = useState<string | null>(null);

  // Access provider profile from Redux state
  const providerProfile = useSelector((state: RootState) => state.providerProfile.details);

  const orders = useSelector((state: RootState) => state.orders.orders);
  const loggedOrderIds = new Set<string>();

  // Log orders when they load
  useEffect(() => {
    if (orders.length > 0) {
      console.log("Orders loaded:", orders);
    }
  }, [orders]);

  useEffect(() => {
    const newOrders = orders.filter(
      (order) =>
        (order.orderStatus === "Pending" || order.orderStatus === "Assigned") &&
        !loggedOrderIds.has(order.id)
    );

    if (newOrders.length > 0) {
      toast.info(
        `You have ${newOrders.length} new order(s) with status Pending or Assigned!`
      );

      // Add the new order IDs to the loggedOrderIds set
      newOrders.forEach((order) => loggedOrderIds.add(order.id));
    }
  }, [orders]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setProviderId(user.uid);
      } else {
        setProviderId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (providerId) {
      dispatch(fetchProviderDetails(providerId));
      dispatch(fetchZoneData(providerId)); // Dispatch the action to fetch zones and regions
      dispatch(fetchOrdersProvider(providerId)); // Trigger the order load as soon as ProvIndex is opened
    }
  }, [dispatch, providerId]);

  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
  };

  const NoRecords: React.FC<{ message?: string }> = ({
    message = "Coming Soon",
  }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Ensures the container takes full viewport height
        }}
      >
        <div
          style={{
            maxWidth: "700px",
            padding: "100px",
            borderRadius: 15,
            textAlign: "center",
            fontFamily: "Roboto",
            backgroundColor: "#f5f5f5",
            fontSize: 45,
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  const tabContent: { [key: string]: JSX.Element } = {
    Dashboard: <Dashboard />,
    Analytics: <Analytics />,
    Requests: <Requests />,
    Bookings: <Bookings />,
    Invoices: <Invoices />,
    Employees: <EmployeeList />,
    "Client Management": <Clients />,
    "Rate Agreement": <RateAgreement />,
    Messages: <NoRecords />,
    "Vehicle Fleet": <VehicleFleet />,
    Surveys: <NoRecords />,
    Tickets: <NoRecords />,
    Profile: <Profile />,
    Debug: <NoRecords />,
  };

  return (
    <>
      <ToastContainer />

      <div style={{ display: "flex", flexDirection: "row", height: "100vh" }}>
        <Sidebar onTabSelect={handleTabSelect} selectedTab={selectedTab} />
        <div style={{ flex: 1, overflowY: "auto" }}>
          {tabContent[selectedTab]}
        </div>
      </div>
    </>
  );
};

export default ProvIndex;
