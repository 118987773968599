import { AnyAction } from 'redux';
import { SET_HISTORY, SET_HISTORY_LOADING, SET_HISTORY_ERROR } from '../actions/processHistoryActions';

export interface HistoryEntry {
  id: string;
  actionType: string;
  newStatus: string;
  prevStatus: string;
  orderId: string;
  customerUid: string;
  providerUid: string;
  timestamp: number | string;
  formattedTimestamp: string;
  actionByUid: string;
}

export interface ProcessHistoryState {
  history: HistoryEntry[];
  loading: boolean;
  error: string | null;
}

const initialState: ProcessHistoryState = {
  history: [],
  loading: false,
  error: null,
};

const processHistoryReducer = (state = initialState, action: AnyAction): ProcessHistoryState => {
  switch (action.type) {
    case SET_HISTORY_LOADING:
      return { ...state, loading: action.payload };
    case SET_HISTORY:
      return { ...state, history: action.payload, error: null };
    case SET_HISTORY_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default processHistoryReducer;
