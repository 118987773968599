import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import {
  SET_PROVIDER_PROFILE_DETAILS,
  SET_LOADING_PROVIDER_PROFILE,
  SET_LOADING_PROVIDER_PROFILE_ACCOUNTS,
  SET_PROVIDER_PROFILE_ACCOUNTS,
} from "./types";
import { db } from "./../../../App";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { RootState } from "../reducers";

// The parameter can be a string or an array of strings
export const fetchProviderDetails = (
  providerIds: string | string[]
): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  // Start loading for both details and accounts
  dispatch({ type: SET_LOADING_PROVIDER_PROFILE, payload: true });
  dispatch({ type: SET_LOADING_PROVIDER_PROFILE_ACCOUNTS, payload: true });

  // Normalize the providerIds to an array
  const ids = Array.isArray(providerIds) ? providerIds : [providerIds];

  try {
    // Fetch all provider documents concurrently
    const providersData = await Promise.all(
      ids.map(async (id) => {
        const providerRef = doc(db, "Providers", id);
        const providerDoc = await getDoc(providerRef);
        if (providerDoc.exists()) {
          let data = providerDoc.data();
          if (data?.createdAt) {
            data.createdAt = data.createdAt.toDate().toISOString();
          }
          // Return an object that includes the provider id (as uid) and its data
          return { uid: providerDoc.id, ...data };
        } else {
          console.log(`Provider document for id ${id} does not exist.`);
          return null;
        }
      })
    );

    // Filter out any providers that were not found
    const validProviders = providersData.filter(
      (provider) => provider !== null
    );

    // Optionally: If there is only one provider,
    // dispatch SET_PROVIDER_PROFILE_DETAILS with that provider.
    // Otherwise, you might choose to handle this differently.
    if (validProviders.length === 1) {
      dispatch({
        type: SET_PROVIDER_PROFILE_DETAILS,
        payload: validProviders[0],
      });
    }
    // Update the provider accounts in your store.
    dispatch({
      type: SET_PROVIDER_PROFILE_ACCOUNTS,
      payload: validProviders,
    });
  } catch (error) {
    console.error("Error fetching provider data:", error);
  } finally {
    // End loading regardless of success or failure
    dispatch({ type: SET_LOADING_PROVIDER_PROFILE, payload: false });
    dispatch({ type: SET_LOADING_PROVIDER_PROFILE_ACCOUNTS, payload: false });
  }

  // Set up real-time updates with onSnapshot for each provider
  // (You can return a combined unsubscribe function if needed.)
  const unsubscribes = ids.map((id) => {
    const providerRef = doc(db, "Providers", id);
    return onSnapshot(
      providerRef,
      (docSnap) => {
        if (docSnap.exists()) {
          let updatedData = docSnap.data();
          if (updatedData?.createdAt) {
            updatedData.createdAt = updatedData.createdAt
              .toDate()
              .toISOString();
          }
          dispatch({
            type: SET_PROVIDER_PROFILE_DETAILS,
            payload: { uid: docSnap.id, ...updatedData },
          });
        }
      },
      (error) => {
        console.error("Error listening to provider updates:", error);
      }
    );
  });

  // Optionally return a combined unsubscribe function if you plan to use it later.
  return () => unsubscribes.forEach((unsub) => unsub());
};
