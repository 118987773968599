import React, { useEffect, useState } from "react";
import { db } from "../../App";
import { SERVER_URL, formatBase64Image } from ".././Functions";
import GradientContainer from "./GradientContainer";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  getDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
//import ProviderManagementModal from "./ProviderManagementModal"; // Assume this is a new modal component for managing providers assigned to a zipcode
import MapComponent from "./MapZones";
import { navy } from "../../styles";
import AddZipCodeModal from "./AddZipCodeModal";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";

import ManageProvidersModal from "./ManageProvidersModal";
import { useSelector } from "react-redux";
import { MdAccountCircle } from "react-icons/md";
import { RootState } from "../store/reducers";
import { Provider } from "../store/actions/types";

const rowStyles: React.CSSProperties = {
  padding: "20px",
  paddingLeft: 30,
  paddingRight: 30,
  //alignItems: "center",
  //border: "1px solid #000000",
  margin: "10px",
  borderRadius: 15,
  backgroundColor: "#f5f5f5",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  color: "black",
  fontSize: "18px",
  fontFamily: "Poppins",
};

interface ProviderData {
  UID: string;
  emailAddress: string;
  rate: number;
  priority: number;
}

interface ZipCodeData {
  id: string;
  ZipCode: string;
  AssignedProviders: ProviderData[];
  RegionalFactor: number;
}

interface CircleData {
  center: { lat: number; lng: number };
  radius: number;
  polygon: { lat: number; lng: number }[];
}

interface ZoneData {
  id: string; // Optional in case of new entries where ID isn't known yet
  ZoneName: string;
  RegionalFactor: number;
  ZipCodes: string[];
  Circles: CircleData[];
  AssignedProviders: ProviderData[];
}

interface RegionData {
  id: string;
  RegionName: string;
  Circles: CircleData[];
  workingHours?: { [key: string]: { startTime: string; endTime: string } };
  selectedDays?: { [key: string]: boolean };
}

const useGetProviderData = () => {
  const providers = useSelector(
     (state: RootState) => state.providerProfileAccounts.accounts
   );

  return providers;
};

const AssignmentZipCode: React.FC = () => {
  const [zipCodes, setZipCodes] = useState<ZipCodeData[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedZone, setSelectedZone] = useState<ZoneData | null>(null);
  const [selectedZoneId, setSelectedZoneId] = useState<string | null>(null);

  const [regions, setRegions] = useState<RegionData[]>([]); // Now typed with RegionData
  const [selectedRegionId, setSelectedRegionId] = useState<string | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<any | null>(null);

  const [mode, setMode] = useState("region"); // 'region' or 'zone'

  const [loadingZones, setLoadingZones] = useState(false); // New loading state

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Regions"), (snapshot) => {
      const updatedRegions = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //@ts-ignore
      setRegions(updatedRegions);
    });

    return () => unsubscribe(); // Clean up the listener when the component unmounts
  }, []);

  //@ts-ignore
  let unsubscribeZones = null;

  const fetchZonesForRegion = (regionId: string) => {
    setLoadingZones(true);

    //@ts-ignore Unsubscribe from any previous subscriptions to avoid memory leaks
    if (unsubscribeZones) {
      unsubscribeZones();
    }

    const zonesQuery = query(
      collection(db, "Zones"),
      where("regionId", "==", regionId)
    );

    // Set up a real-time listener
    unsubscribeZones = onSnapshot(
      zonesQuery,
      (snapshot) => {
        const zonesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        //@ts-ignore
        setZones(zonesData);
        setLoadingZones(false);
      },
      (error) => {
        console.error("Error fetching zones:", error);
        setLoadingZones(false);
      }
    );

    setSelectedRegionId(regionId); // Store the selected region ID
  };

  const handleRegionClick = (region: any) => {
    //@ts-ignore
    setSelectedRegionId(region.id); // Save the current region ID
    setSelectedRegion(region);
    //setMode("zone"); // Switch to zone mode to display zones of this region
  };

  const displayRegionZones = (regionId: string) => {
    setSelectedRegionId(regionId); // Ensure we track which region's zones we are about to display
    fetchZonesForRegion(regionId);
    setMode("zone"); // Switch to zone mode
  };

  const deleteRegion = async (regionId: string) => {
    try {
      await deleteDoc(doc(db, "Regions", regionId));
      console.log(`Region with ID ${regionId} has been deleted.`);
      const newRegions = regions.filter((region) => region.id !== regionId);
      setRegions(newRegions);
      // Reset zones if the deleted region was selected
      if (selectedRegionId === regionId) {
        setZones([]);
        setSelectedRegionId(null);
      }
    } catch (error) {
      console.error("Error deleting region:", error);
    }
  };

  const addButtonHandler = () => {
    setIsModalOpen(true);
  };

  const handleBackToRegions = () => {
    setMode("region"); // Switch back to region mode
    setSelectedRegion(null); // Optionally clear the selected region
    setSelectedRegionId(null); // Clear the selected region ID
    setZones([]); // Clear the zones as well
  };

  const isRegionSelected = (region: RegionData) => {
    //@ts-ignore
    return selectedRegion && selectedRegion.id === region.id;
  };
  const getRegionContainerStyle = (region: RegionData) => {
    return {
      ...rowStyles,
      border: `3px solid white`, // Set default white border for all zones
      borderColor: selectedRegionId === region.id ? "#007ae4" : "white", // Change border color if selected
    };
  };

  const formatWorkingHours = (workingHours: {
    [key: string]: { startTime: string; endTime: string };
  }) => {
    return Object.entries(workingHours).map(([day, hours]) => (
      <div key={day}>
        {day}: {hours.startTime} - {hours.endTime}
      </div>
    ));
  };

  const renderRegions = () => {
    return regions.length > 0 ? (
      regions.map((region, index) => (
        <div
          key={region.id}
          style={getRegionContainerStyle(region)}
          onClick={() => handleRegionClick(region)}
        >
          {/*}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              style={styles.buttonStyle}
              onClick={() => handleMoveUp(index)}
            >
              <IoIosArrowDropupCircle size={20} />
            </button>
            <button
              style={styles.buttonStyle}
              onClick={() => handleMoveDown(index)}
            >
              <IoIosArrowDropdownCircle size={20} />
            </button>
          </div> */}

          <div style={{ display: "flex", marginTop: 15 }}>
            <div style={{ marginRight: "20px" }}>
              <strong>Region Name:</strong> {region.RegionName}
            </div>
            {/*
            <div>
              <strong>Regional Factor: </strong>
              {region.RegionalFactor > 0 ? `${region.RegionalFactor}%` : "Not Set"}
            </div>
        */}
          </div>

          <div style={{ display: "flex", marginTop: 15 }}>
            <div>
              <strong>Circles:</strong>
              {region.Circles && region.Circles.length > 0 ? (
                region.Circles.map((circle, index) => (
                  <div key={index}>
                    <div>
                      <strong>Circle {index + 1}:</strong>
                    </div>
                    <div>
                      Center: ({circle.center.lat.toFixed(4)},{" "}
                      {circle.center.lng.toFixed(4)})
                    </div>
                    <div>Radius: {circle.radius} meters</div>
                  </div>
                ))
              ) : (
                <div>No circles defined.</div>
              )}
            </div>

            <div style={{ display: "flex", marginTop: 15 }}>
              <div>
                <strong>Working Hours:</strong>
                {region.workingHours
                  ? formatWorkingHours(region.workingHours)
                  : "No working hours defined."}
              </div>
              <div style={{ marginLeft: "20px" }}>
                <strong>Selected Days: </strong>
                {region.selectedDays
                  ? Object.keys(region.selectedDays)
                      //@ts-ignore
                      .filter((day) => region.selectedDays[day])
                      .join(", ")
                  : "No selected days defined."}
              </div>
            </div>

            {/*
            <div style={{ marginRight: "20px" }}>
              <strong>Zip Codes: </strong>
              {zone.ZipCodes.length > 0
                ? //@ts-ignore
                  zone.ZipCodes.map((z) => z.zipCode).join(", ")
                : "No zip codes assigned"}
              </div>*/}
          </div>

          {/*
          <div style={styles.providerContainer}>
            {region.AssignedProviders.length > 0 ? (
              region.AssignedProviders.map((provider, index) => {
                const providerData =
                  providers.find((p) => p.UID === provider.UID) || null;
                return (
                  <ProviderItem
                    key={index}
                    provider={provider}
                    index={index}
                    providerData={providerData}
                  />
                );
              })
            ) : (
              <p style={styles.noProvidersText}>
                No providers assigned to this zipcode.
              </p>
            )}
          </div> */}

          <div style={styles.buttonContainer}>
            <button
              style={{ ...styles.button, backgroundColor: "red" }}
              onClick={() => deleteRegion(region.id)}
            >
              Delete Region
            </button>
            <button
              style={{ ...styles.button, marginLeft: "10px" }}
              onClick={() => displayRegionZones(region.id)}
            >
              Display Region Zones
            </button>

            {/*
            <button
              style={{ ...styles.button, marginLeft: "10px" }}
              onClick={() => openModal(region)}
            >
              Update Regional Cost Factor
            </button>
        */}
          </div>
        </div>
      ))
    ) : (
      <div>No regions found.</div>
    );
  };

  const renderZones = () => {
    // Show a loading message while zones are being fetched
    if (loadingZones) {
      return <div>Loading Zones...</div>;
    }

    // If loading is complete but no zones are found, show a specific message
    if (!zones || zones.length === 0) {
      return <div>No zones found for selected region.</div>;
    }
    //@ts-ignore
    const sortedZones = zones.sort((a, b) => a.Priority - b.Priority);

    // Render zones if they are available
    return sortedZones.map((zone, index) => (
      <div
        key={zone.id}
        style={getZoneContainerStyle(zone)}
        onClick={() => handleZoneClick(zone)}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            style={styles.buttonStyle}
            onClick={() => handleMoveUp(index)}
          >
            <IoIosArrowDropupCircle size={20} />
          </button>
          <button
            style={styles.buttonStyle}
            onClick={() => handleMoveDown(index)}
          >
            <IoIosArrowDropdownCircle size={20} />
          </button>
        </div>

        <div style={{ display: "flex", marginTop: 15 }}>
          <div style={{ marginRight: "20px" }}>
            <strong>Zone Name:</strong> {zone.ZoneName}
          </div>
          <div>
            <strong>Regional Factor: </strong>
            {zone.RegionalFactor > 0 ? `${zone.RegionalFactor}%` : "Not Set"}
          </div>
        </div>

        <div style={{ display: "flex", marginTop: 15 }}>
          <div>
            <strong>Circles:</strong>
            {zone.Circles.length > 0 ? (
              zone.Circles.map((circle, index) => (
                <div key={index}>
                  <div>
                    <strong>Circle {index + 1}:</strong>
                  </div>
                  <div>
                    Center: ({circle.center.lat.toFixed(4)},{" "}
                    {circle.center.lng.toFixed(4)})
                  </div>
                  <div>Radius: {circle.radius} meters</div>
                </div>
              ))
            ) : (
              <div>No circles defined.</div>
            )}
          </div>
          <div style={{ marginRight: "20px" }}>
            <strong>Zip Codes: </strong>
            {zone.ZipCodes.length > 0
              ? //@ts-ignore
                zone.ZipCodes.map((z) => z.zipCode).join(", ")
              : "No zip codes assigned"}
          </div>
        </div>

        <div style={styles.providerContainer}>
          {zone.AssignedProviders.length > 0 ? (
            zone.AssignedProviders.map((provider, index) => {
              const providerData =
                providers.find((p: Provider) => p.uid === provider.UID) || null;
              return (
                <ProviderItem
                  key={index}
                  provider={provider}
                  index={index}
                  providerData={providerData}
                />
              );
            })
          ) : (
            <p style={styles.noProvidersText}>
              No providers assigned to this zone.
            </p>
          )}
        </div>

        <div style={styles.buttonContainer}>
          <button
            style={{ ...styles.button, backgroundColor: "red" }}
            onClick={() => deleteZone(zone.id, selectedRegion.id)}
          >
            Delete Zone
          </button>
          <button
            style={{ ...styles.button, marginLeft: "10px" }}
            onClick={() => openManageProvidersModal(zone.id)}
          >
            Manage Providers
          </button>
          <button
            style={{ ...styles.button, marginLeft: "10px" }}
            onClick={() => openModal(zone)}
          >
            Update Regional Cost Factor
          </button>
        </div>
      </div>
    ));
  };

  const [zones, setZones] = useState<ZoneData[] | null>(null);

  const providers = useGetProviderData();

  const [isManageProvidersModalOpen, setIsManageProvidersModalOpen] =
    useState(false);

  const openManageProvidersModal = (zoneId: string) => {
    setIsManageProvidersModalOpen(true);
    setSelectedZoneId(zoneId);
  };

  const closeManageProvidersModal = () => {
    setIsManageProvidersModalOpen(false);
  };

  const existingZipCodes = zipCodes
    ? zipCodes.map((zipCodeData) => zipCodeData.ZipCode)
    : [];

  const defaultProps = {
    center: {
      lat: 33.7175,
      lng: -117.8311,
    },
    zoom: 10,
  };

  const handleZoneClick = (zone: ZoneData) => {
    console.log("Selected Zone:", zone);
    setSelectedZone(zone);
  };

  const renderMarkersAndPolygons = (
    map: any,
    maps: any,
    geoJsonData: any,
    selectedArea: ZoneData | RegionData | null
  ) => {
    // Assuming orders is accessible here, place markers as before
    /*
      orders.forEach((order) => {
        new maps.Marker({
          position: {
            lat: order.address.location.latitude,
            lng: order.address.location.longitude,
          },
          map,
          title: order.orderID,
        });
      });*/

    // Load GeoJSON ZIP code areas
    //map.data.addGeoJson(geoJsonData);

    // Style the ZIP code areas

    const shapes: any[] = [];

    if (selectedArea) {
      // Calculate the center of the zone
      const points: { lat: number; lng: number }[] = [];

      if (selectedArea.Circles) {
        selectedArea.Circles.forEach((circle) => {
          points.push(circle.center);
        });
      }

      //@ts-ignore Collect all centers of zip code polygons (assuming polygons are available and each has at least one coordinate)
      if (selectedArea.ZipCodes && selectedArea.ZipCodes.length > 0) {
        //@ts-ignore
        selectedArea.ZipCodes.forEach((zipCode) => {
          // For simplicity, taking the first coordinate of the polygon as its "center"
          if (zipCode.polygon && zipCode.polygon.length > 0) {
            points.push({
              lat: zipCode.polygon[0].lat,
              lng: zipCode.polygon[0].lng,
            });
          }
        });
      }

      // Calculate the geographic center of all points
      const centerPoint = points.reduce(
        (center, point) => {
          return {
            lat: center.lat + point.lat / points.length,
            lng: center.lng + point.lng / points.length,
          };
        },
        { lat: 0, lng: 0 }
      );

      const content =
        "ZoneName" in selectedArea
          ? `<div style="background-color: white; padding: 5px; border-radius: 5px; display: inline-block; font-size: 18px; font-family: 'PoppinsMedium', sans-serif;">${selectedArea.ZoneName}</div>`
          : `<div style="background-color: white; padding: 5px; border-radius: 5px; display: inline-block; font-size: 18px; font-family: 'PoppinsMedium', sans-serif;">${selectedArea.RegionName}</div>`;

      const infoWindow = new maps.InfoWindow({
        content: content,
        position: centerPoint,
        disableAutoPan: true,
        closeButton: false,
      });

      // Open the info window on the marker
      infoWindow.open(map);

      //shapes.push(zoneNameMarker);
      shapes.push(infoWindow);

      // Render circles
      if (selectedArea && Array.isArray(selectedArea.Circles)) {
        selectedArea.Circles.forEach((circle) => {
          const newCircle = new maps.Circle({
            center: circle.center,
            radius: circle.radius,
            map,
            strokeColor: "#2596be",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#7fe0fe",
            fillOpacity: 0.5,
          });

          shapes.push(newCircle);
        });
      }

      //@ts-ignore Render zip code polygons
      if (selectedArea && Array.isArray(selectedArea.ZipCodes)) {
        //@ts-ignore
        selectedArea.ZipCodes.forEach((zipCode) => {
          if (zipCode.polygon) {
            // Also, ensure that the polygon data is available
            const zipCodePolygon = new maps.Polygon({
              //@ts-ignore
              paths: zipCode.polygon.map((coord) => ({
                lat: coord.lat,
                lng: coord.lng,
              })),
              strokeColor: "#2596be",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#7fe0fe",
              fillOpacity: 0.5,
            });

            zipCodePolygon.setMap(map);
            shapes.push(zipCodePolygon);
          } else {
            console.error("Polygon data is missing for this zip code");
          }
        });
      } else {
        console.log("No zip codes data available or it's not an array");
      }
    }

    return shapes;
  };

  const isZoneSelected = (zone: ZoneData) => {
    return selectedZone && selectedZone.id === zone.id;
  };

  // Function to generate dynamic styles for the zone container div
  const getZoneContainerStyle = (zone: ZoneData) => {
    return {
      ...rowStyles,
      border: `3px solid white`, // Set default white border for all zones
      borderColor: isZoneSelected(zone) ? "#007ae4" : "white", // Change border color if selected
    };
  };

  const deleteZone = async (zoneId: string, regionId: string) => {
    try {
      // Step 1: Delete the selected zone
      await deleteDoc(doc(db, "Zones", zoneId));
      console.log(`Zone with ID ${zoneId} has been deleted.`);

      // Step 2: Fetch remaining zones in the same region
      const zoneQuery = query(
        collection(db, "Zones"),
        where("regionId", "==", regionId)
      );
      const snapshot = await getDocs(zoneQuery);
      const remainingZones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      //@ts-ignore Step 3: Sort zones by their existing priority and reassign priorities based on index
      remainingZones.sort((a, b) => a.Priority - b.Priority);
      const updates = remainingZones.map((zone, index) => {
        return updateDoc(doc(db, "Zones", zone.id), { Priority: index });
      });

      // Step 4: Execute all update operations
      await Promise.all(updates);
      console.log("Zone priorities updated successfully.");
    } catch (error) {
      console.error("Error updating zone priorities or deleting zone:", error);
    }
  };

  const handleMoveUp = async (index: any) => {
    if (index === 0) return; // Can't move up the first item
    //@ts-ignore
    const newZones = [...zones];
    [newZones[index - 1], newZones[index]] = [
      newZones[index],
      newZones[index - 1],
    ];

    // Update Firestore
    await updateZonePriority(newZones[index - 1].id, index - 1);
    await updateZonePriority(newZones[index].id, index);

    setZones(newZones); // Update state
  };

  const handleMoveDown = async (index: any) => {
    //@ts-ignore
    if (index === zones.length - 1) return; // Can't move down the last item
    const newZones = [...zones];
    [newZones[index], newZones[index + 1]] = [
      newZones[index + 1],
      newZones[index],
    ];

    // Update Firestore
    await updateZonePriority(newZones[index].id, index);
    await updateZonePriority(newZones[index + 1].id, index + 1);

    setZones(newZones); // Update state
  };

  const updateZonePriority = async (zoneId: any, newPriority: any) => {
    const zoneDoc = doc(db, "Zones", zoneId);
    try {
      await updateDoc(zoneDoc, { Priority: newPriority });
      console.log(`Zone with ID ${zoneId} priority updated to ${newPriority}.`);
    } catch (error) {
      console.error("Error updating zone priority:", error);
    }
  };

  const openModal = (zipCodeData: any) => {
    //setIsModalOpen(true);
  };

  const openAddZipCodeModal = () => {
    setIsModalOpen(true); // Set isModalOpen to true to show the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const ProviderItem = ({ provider, index, providerData }: any) => (
    <div style={styles.providerItem}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {providerData?.profileImage ? (
          <img
            src={formatBase64Image(providerData.profileImage)}
            alt="Provider Profile Icon"
            style={styles.providerImage}
          />
        ) : (
          <MdAccountCircle size="45" style={styles.providerIcon} />
        )}
        <p style={{ fontFamily: "PoppinsSemi", overflow: "hidden" }}>
          {providerData?.emailAddress || "Unknown"}
        </p>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "20px" }}>
          <p>
            <strong>Provider Priority:</strong> {index + 1}
          </p>
        </div>
        <div>
          <p>
            <strong>Provider Rate:</strong> {provider.rate}%
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <h1
        style={{
          fontSize: 35,
          color: "black",
          fontFamily: "Poppins",
          padding: "20px",
        }}
      >
        {mode === "region" ? "Region Assignments" : "Zone Assignments"}
      </h1>
      <button style={styles.addButton} onClick={addButtonHandler}>
        {mode === "region" ? "Add Region" : "Add Zone"}
      </button>
      {mode === "zone" && (
        <button
          style={{ ...styles.addButton, right: "200px" }}
          onClick={handleBackToRegions}
        >
          Back to Regions
        </button>
      )}
      <div
        style={{
          display: "flex",
          marginTop: 50,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1, overflowY: "scroll", maxHeight: "80vh" }}>
          {/* Adjust maxHeight as needed */}
          {/* Left side for Zip Codes */}
          {mode === "region" ? renderRegions() : renderZones()}
        </div>

        <div style={{ flex: 1, padding: "10px" }}>
          {/* Right side for Map Component */}
          <MapComponent
            renderMarkersAndPolygons={renderMarkersAndPolygons}
            height={"79vh"}
            defaultProps={defaultProps}
            selectedArea={mode === "region" ? selectedRegion : selectedZone} // Pass either selectedRegion or selectedZone
          />
        </div>
      </div>
      {/*isModalOpen && selectedZipCodeData && (
        <ProviderManagementModal
          isOpen={isModalOpen}
          onClose={closeModal}
          zipCodeData={selectedZipCodeData}
          fetchZipCodes={fetchZipCodesAndProviders}
        />
      )*/}
      {isModalOpen && (
        <AddZipCodeModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)} // Pass a function to close the modal
          existingZipCodes={existingZipCodes} // Pass the existing ZIP codes here
          //@ts-ignore
          context={mode} // Pass the mode to the modal
          selectedRegionId={selectedRegionId}
        />
      )}
      <ManageProvidersModal
        areaDocId={selectedZoneId || ""}
        isOpen={isManageProvidersModalOpen}
        onClose={closeManageProvidersModal}
      />
    </div>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  providerContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", // Adjust the minmax value as needed
    gap: "10px", // Adjust the gap between grid items as needed
  },
  providerItem: {
    maxWidth: 300,
    borderWidth: "0px",
    borderColor: "black",
    backgroundColor: "white",
    borderStyle: "solid",
    padding: "10px",
    borderRadius: "15px",
  },
  providerImage: {
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    border: "3px solid #007ae4",
    marginRight: "10px",
    objectFit: "cover",
  },
  providerIcon: {
    borderRadius: "50%",
    border: "3px solid #007ae4",
    marginRight: "10px",
    objectFit: "cover",
  },

  buttonStyle: {
    cursor: "pointer",
    background: "black",
    color: "white",
    border: "none",
    padding: "7px 7px",
    borderRadius: "5px",
    margin: "0 5px",
    display: "flex",
    alignItems: "center",
  },

  addButton: {
    position: "absolute",
    top: "100px",
    right: "60px",
    fontFamily: "PoppinsLight",
    padding: "15px",
    backgroundColor: "black",
    color: "white",
    fontSize: "18px",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  },
  button: {
    fontSize: "16px",
    color: "white", // Text color
    fontFamily: "PoppinsLight",
    backgroundColor: "black", // Background color
    borderRadius: "5px", // Make it round
    padding: "12px 10px", // Padding for sizing (adjust as needed)
    border: "none", // Remove default button border
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
    //marginRight: 40,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start", // Adjust the alignment as needed, could be "space-between", "space-around", etc.
    alignItems: "center",
    padding: "10px 0", // Add some padding on top and bottom for spacing
  },
  providers: {
    maxWidth: 300,
    borderWidth: "0px",
    borderColor: "black",
    backgroundColor: "white",
    borderStyle: "solid", // Add this line to specify the style of the border
    padding: "10px", // Add padding inside the borders
    margin: "10px 0", // Add some margin for spacing between providers
    borderRadius: "15px",
  },
};

export default AssignmentZipCode;
