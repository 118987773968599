import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { db } from '../../../App';
import { collection, getDocs } from 'firebase/firestore';
import { RootState } from '../reducers';

// Action types
export const SET_HISTORY = 'SET_HISTORY';
export const SET_HISTORY_LOADING = 'SET_HISTORY_LOADING';
export const SET_HISTORY_ERROR = 'SET_HISTORY_ERROR';

// Define a ThunkAction that fetches the process history
export const fetchProcessHistory = (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({ type: SET_HISTORY_LOADING, payload: true });
    try {
      const historyCollection = collection(db, 'ProcessHistory');
      const querySnapshot = await getDocs(historyCollection);
      const history = querySnapshot.docs
        .map((doc) => {
          const data = doc.data();
          let formattedTimestamp: string;
          if (typeof data.timestamp === 'number') {
            formattedTimestamp = new Date(data.timestamp).toLocaleString();
          } else {
            formattedTimestamp = 'Unknown Timestamp';
          }
          return {
            id: doc.id,
            actionType: data.actionType || 'No Action Type',
            newStatus: data.newStatus || 'Error Occurred',
            prevStatus: data.prevStatus || 'No Previous Status',
            orderId: data.orderId || 'No Order ID',
            customerUid: data.customerUid || 'Error loading Customer Data',
            providerUid: data.providerUid || 'No Provider Assigned',
            timestamp: data.timestamp,
            formattedTimestamp,
            actionByUid: data.actionByUid || 'Unknown User',
          };
        })
        .sort((a, b) => b.timestamp - a.timestamp);
      dispatch({ type: SET_HISTORY, payload: history });
    } catch (error) {
      console.error('Error fetching process history:', error);
      dispatch({ type: SET_HISTORY_ERROR, payload: error });
    } finally {
      dispatch({ type: SET_HISTORY_LOADING, payload: false });
    }
  };
