// src/store/providersReducer.js
const initialState = {
  providers: [],
};

function providersReducer(state = initialState, action:any) {
  switch (action.type) {
    case 'SET_PROVIDERS':
      return {
        ...state,
        providers: action.payload,
      };
    default:
      return state;
  }
}

export default providersReducer;

// Action creators
export const setProviders = (providers:any) => ({
  type: 'SET_PROVIDERS',
  payload: providers,
});



//GET RID OF THIS