import React, { useEffect, useRef, useState } from "react";
import { db } from "../../App";
import { collection, doc, onSnapshot, getDoc } from "firebase/firestore";

import GoogleMapReact from "google-map-react";
import { activeColor } from "../../styles";
import { formatBase64Image } from "../Functions";
import { IoIosArrowDropright } from "react-icons/io";
import RowHeader from "./RowHeaderNew";
import BookingDetail from "../Provider/BookingDetail";
import NoRecords from "../NoRecords";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { Customer } from "../store/actions/types";
import LoadingSpinner from "../LoadingSpinner";

interface Order {
  id: string;
  orderID: string;
  orderStatus: string;
  address: {
    location: {
      latitude: number;
      longitude: number;
    };
  };
  elevate: boolean;
}

const Dispatch: React.FC = () => {
  //const [history, setHistory] = useState<HistoryEntry[]>([]);
  //const [customers, setCustomers] = useState<Customer[]>([]);
  //const [providers, setProviders] = useState<Provider[]>([]);
  //const [orders, setOrders] = useState<Order[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );

  const mapRef = useRef();
  const mapsRef = useRef();
  const markersRef = useRef([]);
  const isMapLoaded = useRef(false); // Track if the map has been loaded

  const history = useSelector(
    (state: RootState) => state.processHistory.history
  );
  const loading = useSelector(
    (state: RootState) => state.processHistory.loading
  );

  // Assuming orders, customers, and providers are stored in their own slices:
  const orders = useSelector((state: RootState) => state.orders.orders);
  const customers = useSelector(
    (state: RootState) => state.customers.customers
  );
  const providers = useSelector(
    (state: RootState) => state.providerProfileAccounts.accounts
  );

  const providerLoading = useSelector(
    (state: RootState) => state.providerProfileAccounts.loading
  );
  ;

  console.log("providerLoading is:", providerLoading);

  
  const handleHistoryClick = (orderId: string, customerUid: string) => {
    console.log(orderId + "/" + customerUid);
    const order = orders.find((order) => order.id === orderId);
    // Look up the customer by uid
    const customer = customers.find((customer) => customer.uid === customerUid);
    console.log(customer + "/" + order);
    if (order && customer) {
      // If your component expects a Customer with an 'id' field,
      // you can map uid to id:
      setSelectedOrder(order);
      //@ts-ignore
      setSelectedCustomer({ ...customer, id: customer.uid });
    }
  };

  /*
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "ProcessHistory"),
      (querySnapshot) => {
        const updatedHistory = querySnapshot.docs
          .map((doc) => {
            const data = doc.data();
            let formattedTimestamp: string;

            // Ensure the timestamp is a number and convert it to a date string
            if (typeof data.timestamp === "number") {
              formattedTimestamp = new Date(data.timestamp).toLocaleString();
            } else {
              formattedTimestamp = "Unknown Timestamp";
            }

            return {
              id: doc.id,
              actionType: data.actionType || "No Action Type",
              newStatus: data.newStatus || "Error Occuerd",
              prevStatus: data.prevStatus || "No Previous Status",
              orderId: data.orderId || "No Order ID",
              customerUid: data.customerUid || "Error loading Customer Data",
              providerUid: data.providerUid || "No Provider Assigned",
              timestamp: data.timestamp, // Keep the original timestamp for sorting
              formattedTimestamp, // Use formatted timestamp for display
              actionByUid: data.actionByUid || "Unknown User",
            };
          })
          .sort((a, b) => {
            // Sort using the original timestamp for accuracy
            return b.timestamp - a.timestamp;
          });

        setHistory(updatedHistory);
      },
      (error) => {
        console.error("Error fetching process history:", error);
      }
    );

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, []);

  useEffect(() => {
    if (history.length > 0) {
      const fetchOrders = async () => {
        const orderIds = new Set(); // This set will track order IDs to ensure uniqueness
        const loadedOrders = await Promise.all(
          history.map(async (entry) => {
            if (!orderIds.has(entry.orderId)) {
              // Only fetch if the order ID hasn't been processed yet
              orderIds.add(entry.orderId);
              const orderDoc = await getDoc(doc(db, "Orders", entry.orderId));
              return { ...orderDoc.data(), id: orderDoc.id };
            }
            return null;
          })
        );
        //@ts-ignore
        setOrders(loadedOrders.filter((order) => order !== null));
      };
      fetchOrders();
    }
  }, [history]);

  useEffect(() => {
    const fetchCustomerAndProviderData = async () => {
      const customerIds = new Set();
      const providerIds = new Set();

      // Collect unique customer and provider IDs from history
      history.forEach((item) => {
        if (item.customerUid) customerIds.add(item.customerUid);
        if (item.providerUid) providerIds.add(item.providerUid);
      });

      // Fetch customer data
      const customerPromises = Array.from(customerIds).map(async (id) => {
        const docRef = doc(db, "Users", id as string); // Correctly reference the Users collection
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // Ensure we use the document ID as `id` in the state object
          return { id: docSnap.id, ...docSnap.data() };
        }
        return null;
      });

      // Fetch provider data
      const providerPromises = Array.from(providerIds).map(async (id) => {
        const docRef = doc(db, "Providers", id as string); // Correctly reference the Providers collection
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // Ensure we use the document ID as `id` in the state object
          return { id: docSnap.id, ...docSnap.data() };
        }
        return null;
      });

      // Resolve promises and set state
      const customers = await Promise.all(customerPromises);
      const providers = await Promise.all(providerPromises);

      //@ts-ignore
      setCustomers(customers.filter((customer) => customer !== null)); // Filter out null values if any document does not exist
      //@ts-ignore
      setProviders(providers.filter((provider) => provider !== null)); // Filter out null values if any document does not exist
    };

    if (history.length > 0) {
      fetchCustomerAndProviderData();
    }
  }, [history]); // Re-run this effect if history changes

  */

  const defaultProps = {
    center: {
      lat: 33.7175,
      lng: -117.8311,
    },
    zoom: 10,
  };

  const addMarkers = () => {
    //@ts-ignore
    markersRef.current.forEach((marker) => marker.setMap(null)); // Clear existing markers
    markersRef.current = [];

    orders.forEach((order) => {
      if (order.address && order.address.location) {
        const backgroundStyle = getOrderBackground(order.orderStatus);

        const contentString = `
        <div style="padding: 0px; max-width: 200px;">
          <h3 style="margin: 0; font-size: 20px;">Order ID: #${
            order.orderID || "No ID"
          }</h3>
          <div style="padding: 10px; margin-top:10px; border-radius: 0px; font-family: PoppinsBold; font-size: 14px; ${backgroundStyle} color: black; text-align: center;">
          <p style="margin: 0; color: white;">Status: ${order.orderStatus}</p>
          </div>
        </div>
      `;

        //@ts-ignore
        const infowindow = new mapsRef.current.InfoWindow({
          content: contentString,
          position: {
            lat: order.address.location.latitude,
            lng: order.address.location.longitude,
          },
        });

        infowindow.open(mapRef.current); // Open InfoWindow directly without attaching to a marker

        //@ts-ignore Keep track of InfoWindows instead of markers
        markersRef.current.push(infowindow);
      }
    });
  };

  useEffect(() => {
    if (isMapLoaded.current && orders.length) {
      addMarkers();
    }
  }, [orders]);

  const handleApiLoaded = ({ map, maps }: any) => {
    mapRef.current = map;
    mapsRef.current = maps;
    isMapLoaded.current = true;
    addMarkers(); // Initialize markers once the map is ready
  };

  const getOrderBackground = (status: string) => {
    switch (status) {
      case "Pending":
      case "Assigned":
        return "flash-yellow-animation"; // Flashing yellow
      case "Ongoing":
        return "flash-navy-animation"; // Flashing navy
      case "Scheduled":
        return "background-color: #B700ff;"; // Static purple
      case "Complete":
        return "background-color: #00FF70;"; // Static green
      case "Canceled":
        return "background-color: red;"; // Static red
      default:
        return "background-color: grey;"; // Static grey for other statuses
    }
  };

  const getHistoryBackground = (
    actionType: string,
    orderStatus: string,
    elevate: boolean
  ) => {
    if (actionType === "Elevate" && elevate) {
      return "flash-yellow-animation"; // Apply flashing yellow when actionType is Elevate and elevate is true
    }

    switch (actionType) {
      // case "Assigned":
      case "Ongoing":
        return "flash-navy-animation"; // Apply flashing navy for Ongoing
      default:
        return ""; // No special animation class for other statuses
    }
  };

  const getActionStyle = (status: string): React.CSSProperties => {
    let statusColor = "blue"; // Default background color

    if (status === "Pending") {
      statusColor = "#FFCC00"; // Darker yellow
    } else if (status === "Assigned") {
      statusColor = "#FFCC00"; // Darker yellow
    } else if (status === "Scheduled") {
      statusColor = "#B700ff";
    } else if (status === "Ongoing") {
      statusColor = activeColor;
    } else if (status === "Complete") {
      statusColor = "#00FF70";
    } else if (status === "Canceled") {
      statusColor = "red";
    }

    return {
      //flexBasis: columnWidths.status,
      textAlign: "center",
      backgroundColor: "black",
      //width: "70%",
      color: "white",
      fontSize: "14px",
      fontFamily: "PoppinsBold",
      border: `2px solid ${statusColor}`,
      paddingTop: 5,
      paddingBottom: 5,

      overflow: "hidden",
      textOverflow: "ellipsis",
      //borderRadius: 7,
    };
  };

  const getStatusStyle = (status: string): React.CSSProperties => {
    let backgroundColor = "blue"; // Default background color

    switch (status) {
      case "Pending":
      case "Assigned":
        backgroundColor = "#FFCC00"; // Darker yellow for Pending and Assigned
        break;
      case "Scheduled":
        backgroundColor = "#B700ff"; // Purple for Scheduled
        break;
      case "Ongoing":
        backgroundColor = activeColor; // Use the active color for Ongoing
        break;
      case "Complete":
        backgroundColor = "#00FF70"; // Green for Complete
        break;
      case "Canceled":
        backgroundColor = "red"; // Red for Canceled
        break;
      default:
        backgroundColor = "grey"; // Default grey for unknown statuses
        break;
    }

    return {
      textAlign: "center",
      backgroundColor: backgroundColor, // Set the background color based on the status
      //width: "70%",
      color: "white",
      fontSize: "14px",
      fontFamily: "PoppinsBold",
      padding: 5,
      //borderRadius: 15,
      border: "3px solid black",
      //paddingBottom: 5,
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
  };

  const getUserEmailFromUid = (uid: any) => {
    if (uid === "Admin" || uid === "System") {
      return uid;
    }
    const customer = customers.find((customer) => customer.uid === uid);
    if (customer) {
      return customer.emailAddress;
    }
    const provider = providers.find((provider) => provider.uid === uid);
    if (provider) {
      return provider.emailAddress;
    }
    return "No Assignment";
  };

  const renderUserCell = (email: any, imageUrl: any) => {
    if (email === "") {
      // When email is empty, show the small spinner
      return (
        <div
          style={{
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="spinner-small" />
        </div>
      );
    }
    
    if (email === "No Assignment") {
      return (
        <div
          style={{
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          No Assignment
        </div>
      );
    }
    
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        {imageUrl && (
          <img
            src={formatBase64Image(imageUrl)}
            alt="Profile"
            style={styles.imageStyles}
          />
        )}
        <div
          style={{
            marginLeft: imageUrl ? "10px" : "0",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left",
            whiteSpace: "nowrap",
          }}
        >
          {email}
        </div>
      </div>
    );
  };
  
  

  const titles = {
    id: "#",
    time: "ACTION DATE/TIME",
    provider: "PROVIDER EMAIL",
    customer: "CUSTOMER EMAIL",
    actionBy: "ACTION BY",
    statusChange: "STATUS CHANGE",
    actionType: "ACTION TYPE",
  };

  const percentages = {
    id: "3.7%",
    time: "14.81%",
    provider: "18.52%",
    customer: "18.52%",
    actionBy: "11.11%",
    statusChange: "22.22%",
    actionType: "11.11%",
  };

  return (
    <div>
      {selectedOrder && selectedCustomer ? (
        <>
          <h1
            style={{
              fontSize: 35,
              color: "black",
              fontFamily: "Poppins",
              padding: "20px",
            }}
          >
            Booking Detail
          </h1>
          <BookingDetail
            //@ts-ignore
            order={selectedOrder}
            //@ts-ignore
            customer={selectedCustomer}
            onBack={() => {
              setSelectedOrder(null);
              setSelectedCustomer(null);
            }}
            admin={true}
          />
        </>
      ) : (
        <>
          <h1
            style={{
              fontSize: 35,
              color: "black",
              fontFamily: "Poppins",
              padding: "20px",
            }}
          >
            Dispatch
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "PoppinsSemi",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                height: "40vh",
                width: "auto",
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 5,
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBsP52q1Y_t08O_ndbiyUuFNi9KKGgATrs",
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleApiLoaded}
              />
            </div>
            <RowHeader titles={titles} percentages={percentages} />
            <div
              style={{
                flex: 1,
                overflowY: "auto",
                paddingLeft: "10px",
                paddingRight: "10px",
                maxHeight: "43vh",
              }}
            >
              {loading || orders.length === 0 ? (
               <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
               <LoadingSpinner />
             </div>
             
              ) : (
                history.map((entry) => {
                  const relatedOrder = orders.find(
                    (order) => order.id === entry.orderId
                  );
                  const orderStatus = relatedOrder
                    ? relatedOrder.orderStatus
                    : "Unknown";

                  const elevate = relatedOrder ? relatedOrder.elevate : false;

                  const actionByEmail = getUserEmailFromUid(entry.actionByUid);

                  const provider = providers.find(
                    (provider) => provider.uid === entry.providerUid
                  );
                  
                  let providerEmail = "";
                  if (provider) {
                    //@ts-ignore
                    providerEmail = provider.emailAddress;
                  } else {
                    // If no provider is found, use the loading flag:
                    providerEmail = providerLoading ? "" : "No Assignment";
                  }
                  
                  
                  const providerImageUrl = provider ? provider.profileImage : "";
                  
                  const customer = customers.find(
                    (customer) => customer.uid === entry.customerUid
                  );

                  
                  const customerEmail = customer ? customer.emailAddress : "";
             
                  const customerImageUrl = customer
                    ? customer.profileImage
                    : "";

                  return (
                    <div
                      key={entry.id}
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "0.5fr 2fr 2.5fr 2.5fr 1.5fr 3fr 1.5fr",
                        gap: "10px",
                        alignItems: "center",
                        padding: "10px",
                        margin: "10px 0",
                        border: "1px solid #007ae4",
                        borderRadius: "10px",
                        boxSizing: "border-box",
                        minWidth: "600px",
                        fontSize: 15,
                      }}
                      onClick={() =>
                        handleHistoryClick(entry.orderId, entry.customerUid)
                      }
                    >
                      <div
                        style={{
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        #
                        {relatedOrder
                          ? relatedOrder.orderID
                          : "Unknown Order ID"}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {entry.formattedTimestamp}
                      </div>
                      {renderUserCell(providerEmail, providerImageUrl)}
                      {renderUserCell(customerEmail, customerImageUrl)}
                      <div
                        style={{
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {actionByEmail}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span style={getStatusStyle(entry.prevStatus)}>
                          {entry.prevStatus}
                        </span>
                        <IoIosArrowDropright color={activeColor} size={20} />
                        <span style={getStatusStyle(entry.newStatus)}>
                          {entry.newStatus}
                        </span>
                      </div>
                      <div
                        className={getHistoryBackground(
                          entry.actionType,
                          orderStatus,
                          elevate
                        )}
                        style={getActionStyle(entry.actionType)}
                      >
                        {entry.actionType}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  imageStyles: {
    width: "30px",
    height: "30px",
    borderRadius: "25%",
    border: "3px solid #007ae4",
    objectFit: "cover",
    //marginBottom: "40px",
    alignSelf: "center", // Center the image within its row
  },
};

export default Dispatch;
