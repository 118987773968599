import { combineReducers } from 'redux';
import orderReducer from './orderReducer';
import customerReducer from './customerReducer';
import invoiceReducer from './invoiceReducer';
import zonesReducer from './zonesReducer';

import assignmentsReducer from './assignmentsReducer';
import prospectsReducer from './prospectsReducer';
import notificationsReducer from './notificationsReducer';
import pricingReducer from './pricingReducer';
import couponsReducer from './couponsReducer';
import providersReducer from './providersReducer';
import providerProfileReducer from './providerProfileReducer';
import processHistoryReducer from './processHistoryReducer';
import dispatchReducer from './dispatchReducer';
import providerProfileAccountsReducer from './providerProfileAccountsReducer';


const rootReducer = combineReducers({
  orders: orderReducer,
  customers: customerReducer,
  providerProfile: providerProfileReducer,
  providers: providersReducer, //remove this and the one above it, have them all use 
  providerProfileAccounts: providerProfileAccountsReducer, // USE THIS ONE
  invoices: invoiceReducer,
  zoneData: zonesReducer,
  assignments: assignmentsReducer,
  prospects: prospectsReducer,
  notifications: notificationsReducer,
  pricing: pricingReducer,
  coupons: couponsReducer,
  processHistory: processHistoryReducer,
  dispatchData: dispatchReducer, // new


});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
