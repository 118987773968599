import { AnyAction } from 'redux';
import { 
  SET_PROVIDER_PROFILE_ACCOUNTS, 
  SET_LOADING_PROVIDER_PROFILE_ACCOUNTS 
} from '../actions/types';

export interface ProviderProfileAccount {
  uid: string;
  emailAddress?: string;
  profileImage?: string;
  // Add any additional fields as needed
  [key: string]: any;
}

export interface ProviderProfileAccountsState {
  accounts: ProviderProfileAccount[];
  loading: boolean;
}

const initialState: ProviderProfileAccountsState = {
  accounts: [],
  loading: false,
};

const providerProfileAccountsReducer = (
  state = initialState, 
  action: AnyAction
): ProviderProfileAccountsState => {
  switch (action.type) {
    case SET_PROVIDER_PROFILE_ACCOUNTS:
      // Merge the incoming profiles (assumed to be an array or single object)
      // with the existing ones. Avoid duplicate entries by checking uid.
      const incoming = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      const mergedAccounts = [...state.accounts];
    
      incoming.forEach((newProfile) => {
        const exists = mergedAccounts.some(
          (profile) => profile.uid === newProfile.uid
        );
        if (!exists) {
          mergedAccounts.push(newProfile);
        } else {
          // Optionally update the existing profile with new data:
          const index = mergedAccounts.findIndex(
            (profile) => profile.uid === newProfile.uid
          );
          mergedAccounts[index] = { ...mergedAccounts[index], ...newProfile };
        }
      });
    
      return {
        ...state,
        accounts: mergedAccounts,
      };

    case SET_LOADING_PROVIDER_PROFILE_ACCOUNTS:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default providerProfileAccountsReducer;
