// src/store/reducers/dispatchReducer.ts
import { AnyAction } from 'redux';
import { SET_DISPATCH_HISTORY, SET_DISPATCH_LOADING, SET_DISPATCH_ERROR } from '../actions/types';

export interface DispatchState {
  history: any[];
  loading: boolean;
  error: string | null;
}

const initialState: DispatchState = {
  history: [],
  loading: false,
  error: null,
};

const dispatchReducer = (state = initialState, action: AnyAction): DispatchState => {
  switch(action.type) {
    case SET_DISPATCH_LOADING:
      return { ...state, loading: action.payload };
    case SET_DISPATCH_HISTORY:
      return { ...state, history: action.payload, error: null };
    case SET_DISPATCH_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default dispatchReducer;
