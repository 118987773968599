// Dashboard.tsx
import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import OrderRow from "./OrderRow";
import { useSelector } from "react-redux"; // Import useSelector
import { RootState } from "../store/reducers";
import OrdersFilter from "./OrdersFilter";
import TitleBar from ".././TitleBar";
import RowHeader from "./RowHeaderNew";
import NoRecords from "../NoRecords";
import LoadingSpinner from "../LoadingSpinner";

const Orders: React.FC = (
  {
    //dashboardView,
    //handleDashboardViewChange,
    //renderMarkers,
    //defaultProps,
  }
) => {
  const { orders, loading } = useSelector((state: RootState) => state.orders);

  //const [orders, setOrders] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filters, setFilters] = useState({
    area: "",
    orderId: "",
    status: "",
  });

  const handleFilterChange = (newFilters: {
    area: string;
    orderId: string;
    status: string;
  }) => {
    setFilters(newFilters);
  };
  // Adjust the filter logic to include orderId and status
  const filteredOrders = orders.filter(
    (order: any) =>
      (filters.area ? order.address.zipCodeAddress === filters.area : true) &&
      (filters.orderId ? order.orderID === filters.orderId : true) &&
      (filters.status
        ? order.orderStatus.toLowerCase() === filters.status
        : true) // Corrected line
  );

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  //console.log(orders);

  const titles = {
    id: "#",
    customer: "CUSTOMER UID",
    provider: "PROVIDER UID",
    area: "AREA",
    serviceDateTime: "SERVICE DATE/TIME",
    serviceTypePrice: "SERVICE TYPE/PRICE",
    status: "STATUS",
    details: "DETAILS",
  };

  const percentages = {
    id: "7%",
    customer: "18%",
    provider: "18%",
    area: "9%",
    serviceDateTime: "13%",
    serviceTypePrice: "13%",
    status: "12%",
    details: "10%",
  };

  return (
    <div>
      <TitleBar title="Orders" />

      <OrdersFilter onFilterChange={handleFilterChange} />

      <RowHeader titles={titles} percentages={percentages} />

      <div style={styles.scrollableListContainer}>
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <LoadingSpinner />
          </div>
        ) : filteredOrders.length === 0 ? (
          <NoRecords />
        ) : (
          filteredOrders.map((order: any, index: number) => (
            <OrderRow
              key={index}
              order={order}
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
            />
          ))
        )}
      </div>
    </div>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  animatedGradientContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 50,
    height: "100%",

    background: "linear-gradient(-45deg, #98fbc1, #7fe0fe, #98fbc1, #7fe0fe)",
    backgroundSize: "400% 400%",
    animation: "gradientAnimation 15s ease infinite",
  },

  dashboardTabs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  scrollableListContainer: {
    maxHeight: "72vh", // Adjust the height as necessary
    overflowY: "auto", // Enable vertical scrolling
    //backgroundColor:'pink'
  },

  tabButton: {
    border: "none",
    fontSize: "20px",
    margin: "0 auto",
    display: "block",
    padding: "5px 30px",
    color: "white", // Text color
    backgroundColor: "black", // Background color
    //borderRadius: '25px', // Make it round
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
  },
};

export default Orders;

/*
      <div style={styles.dashboardTabs}>
        <button
          style={styles.tabButton}
          onClick={() => handleDashboardViewChange("LIST")}
        >
          List
        </button>
        <button
          style={styles.tabButton}
          onClick={() => handleDashboardViewChange("MAP")}
        >
          Map
        </button>
      </div>
      {dashboardView === "LIST" ? (

*/
